import React, { useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { Lock } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { addLogIn } from "../../features/signIn/signIn.action";
import { Alert } from "@material-ui/lab";
import { resetState } from "../../features/signIn/signIn.slicer";

const SigninSchema = Yup.object().shape({
  username: Yup.string().required("Required"),
  password: Yup.string().min(8).max(32).required("Required"),
});

const SignIn = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginLoadingSucess, loginLoadingFailed } = useSelector(
    (state) => state.LoginSlicer
  );
  const { data } = useSelector((state) => state.LoginSlicer.login);
  const { error } = useSelector((state) => state.LoginSlicer);

  if (loginLoadingSucess && data) {
    if (data?.token) {
      localStorage.setItem("AdminHeaven", data?.token);
      navigate("/dashboard");
    }
  }

  // useEffect(() => {
  //   if (error) {
  //     setTimeout(() => {
  //       dispatch(resetState());
  //     }, 1000);
  //   }
  // }, [dispatch, error]);

  return (
    <Box className={classes.container} p={3}>
      <Box>
        <Typography align="center"></Typography>
        <Typography variant="h5" align="center">
          <Lock /> Sign In
        </Typography>
      </Box>
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={SigninSchema}
        onSubmit={(values) => {
          // same shape as initial values
          const payload = {
            username: values.username,
            password: values.password,
          };

          // send to server

          dispatch(addLogIn(payload));
        }}
      >
        {({
          errors,
          touched,
          values,
          handleSubmit,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            {loginLoadingFailed && (
              <Alert severity="error">Something went really wrong!</Alert>
            )}
            {loginLoadingSucess ? (
              <>
                {" "}
                {error ? (
                  <Alert severity="error">{error}</Alert>
                ) : (
                  <Alert severity="success">Log In success</Alert>
                )}
              </>
            ) : null}

            <br />

            <Box>
              <TextField
                variant="outlined"
                placeholder="User Name"
                type="text"
                name="username"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
                fullWidth
                label="User Name"
                error={Boolean(errors.username && touched.username)}
              />
              {errors.username && touched.username ? (
                <FormHelperText error={true}>{errors.username}</FormHelperText>
              ) : null}
            </Box>
            <Box pt={2}>
              <TextField
                variant="outlined"
                placeholder="Password"
                type="password"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                fullWidth
                label="Password"
                error={Boolean(errors.password && touched.password)}
              />
              {errors.password && touched.password ? (
                <FormHelperText error={true}>{errors.password}</FormHelperText>
              ) : null}
            </Box>

            <Box pt={2} display="flex" justifyContent="center">
              <Button
                className={classes.btn}
                variant="contained"
                color="primary"
                type="submit"
              >
                Log In
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SignIn;

const useStyles = makeStyles(() => ({
  container: {},
}));
