import React from "react";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(3),
    maxWidth: 400,
    textAlign: "center",
  },
  buttonWrapper: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
  },
}));

const DeleteConfirmationModal = ({ open, onClose, onDelete }) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={onClose} className={classes.modal}>
      <div className={classes.paper}>
        <Typography variant="h6" gutterBottom>
          Confirm Delete
        </Typography>
        <Typography variant="body1" paragraph>
          Are you sure you want to delete this item?
        </Typography>
        <div className={classes.buttonWrapper}>
          <Button variant="contained" color="secondary" onClick={onDelete}>
            Delete
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={onClose}
            style={{ marginLeft: 10 }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmationModal;
