import { Add, Payment, Security } from "@material-ui/icons";
import order from "../../assets/sidebaricons/order.svg";
import rca from "../../assets/sidebaricons/rca.svg";

const SideBarArray = [
  {
    text: "videos",
    icon: <Add />,
    link: "/dashboard",
  },
  {
    text: "Add Plan",
    icon: <Payment />,
    link: "/dashboard/add-plan",
  },
  {
    text: "Change Password",
    icon: <Security />,
    link: "/dashboard/change-password",
  },
];

export default SideBarArray;
