import { createSlice } from "@reduxjs/toolkit";
import { addPlanUpload, allDocument } from "./planForm.action";

const initialState = {
  imageLoading: false,
  imageLoadingSucess: false,
  imageLoadingFailed: false,

  allDoc: [],
  allDocLoading: false,
  allDocLoadingSucess: false,
  allDocLoadingFailed: false,
};

const planSlicer = createSlice({
  name: "addPlanUpload/upload",
  initialState: initialState,

  extraReducers: {
    [addPlanUpload.pending]: (state) => {
      state.imageLoading = true;
    },
    [addPlanUpload.fulfilled]: (state, action) => {
      state.imageLoadingSucess = true;
    },
    [addPlanUpload.rejected]: (state, action) => {
      state.imageLoadingFailed = false;
    },
    [allDocument.pending]: (state) => {
      state.allDocLoading = true;
      state.allDocLoadingSucess = false;
      state.allDocLoadingFailed = false;
    },
    [allDocument.fulfilled]: (state, action) => {
      state.allDocLoadingSucess = true;
      state.allDocLoadingFailed = false;
      state.allDocLoading = false;
      state.allDoc = action.payload;
    },
    [allDocument.rejected]: (state) => {
      state.allDocLoadingFailed = true;
      state.allDocLoadingSucess = false;
      state.allDocLoading = false;
    },
  },
});

export default planSlicer.reducer;
