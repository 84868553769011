import { createAsyncThunk } from "@reduxjs/toolkit";
import { deleteDocumentById, getAllDocuments, imageUpload } from "./api";
import toast from "react-hot-toast";

export const addImageUpload = createAsyncThunk(
  "imageUpload/upload",
  async (payload) => {
    const resultPromise = new Promise((resolve, reject) => {
      imageUpload(payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch(({ response }) => {
          reject(response.data?.error || "Something went wrong");
        });
    });

    toast.promise(resultPromise, {
      loading: "Adding Form...",
      success: "Added Form successfully",
      error: (err) => err,
    });

    return await resultPromise;
  }
);

export const allDocument = createAsyncThunk(
  "allDocuments/docs",
  async (payload) => {
    try {
      const res = await getAllDocuments(payload);
      return res.data;
    } catch (err) {
      return err.response;
    }
  }
);

//delete in
export const deleteDoc = createAsyncThunk(
  "decument/deleteDocument",
  async (payload) => {
    const resultPromise = new Promise((resolve, reject) => {
      deleteDocumentById(payload)
        .then((res) => {
          resolve(res.data);
        })
        .catch(({ response }) => {
          reject(response.data?.error || "Something went wrong");
        });
    });

    toast.promise(resultPromise, {
      loading: "Deleting Form...",
      success: "Delete Form successfully",
      error: (err) => err,
    });

    return await resultPromise;
  }
);
