import React, { useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { Box, Button, Card, CardMedia, Typography } from "@material-ui/core";

import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: 2,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
 
    border: "2px dashed #aaa",
    borderRadius: "4px",
    cursor: "pointer",
    padding: "0.5em",
    overflowY: "auto", // Enable vertical scrolling
    maxHeight: "300px", // Set a max height for the container
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "1fr",
    },
  },
  uploadButton: {
    marginTop: theme.spacing(2),
  },
  input: {
    display: "none",
  },
  cardContainer: {
    display: "flex",
    gap: 4,
    marginTop: theme.spacing(2),
    flexWrap: "wrap",
    justifyContent: "center",
  },
  card: {
    width: "80px",
    height: "80px", // Set height to allow for image display
    position: "relative",
  },
  cardMedia: {
    height: "100%", // Adjust to fill the card
    objectFit: "cover", // Maintain aspect ratio and cover the card
  },
  cancelIcon: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
  },
}));

const UploadImagesBox = ({ formik, edit }) => {
  const classes = useStyles();
  const fileInputRef = useRef(null);

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();

    formik.setFieldValue("images", [
      ...formik.values.images,
      ...Array.from(event.dataTransfer.files), // Handle multiple files from drop
    ]);
  };

  const handleFileInputChange = (event) => {
    const files = Array.from(event.target.files); // Get all files from the input
    const validFiles = files.filter(
      (file) => file.type.startsWith("image/") && file.size <= 5000000 // Filter by size and type
    );

    if (validFiles.length > 0 && formik.values.images.length + validFiles.length <= 300) {
      formik.setFieldValue("images", [...formik.values.images, ...validFiles]); // Add multiple valid files
    }
  };

  const handleCancelImage = (index) => {
    const newArray = formik.values.images
      .slice(0, index)
      .concat(formik.values.images.slice(index + 1));
    formik.setFieldValue("images", newArray);
  };

  return (
    <Box
      className={classes.container}
      onDrop={handleDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <Box style={{ textAlign: "center" }}>
        <input
          id="upload-button"
          type="file"
          ref={fileInputRef}
          className={classes.input}
          name="images"
          multiple // Enable multiple file selection
          onChange={handleFileInputChange}
          accept="image/*"
          required={!edit}
        />
        <Button
          className={classes.uploadButton}
          onClick={() => fileInputRef.current.click()}
          variant="contained"
        >
          Upload Images
        </Button>
        <Box mt={1}>
          <Typography variant="body2" style={{ color: "#D2D2D2CC" }}>
            Max size 5MB, .jpg .png only
          </Typography>
        </Box>
      </Box>

      <Box>
        {formik.values.images && formik.values.images.length > 0 ? (
          <Box className={classes.cardContainer}>
            {formik.values.images.map((file, index) => (
              <Card key={index} className={classes.card}>
                <CancelIcon
                  className={classes.cancelIcon}
                  onClick={() => handleCancelImage(index)}
                />
                <CardMedia
                  className={classes.cardMedia}
                  image={file.name ? URL.createObjectURL(file) : file}
                  title={file.name}
                />
              </Card>
            ))}
          </Box>
        ) : (
          <Box>
            <Typography variant="body1" style={{ color: (theme) => theme.palette.secondary.grey }}>
              Ads with pictures get 5x more views and leads
            </Typography>
            <Typography variant="body1" style={{ color: (theme) => theme.palette.secondary.grey }}>
              Upload good quality pictures with proper lighting
            </Typography>
            <Typography variant="body1" style={{ color: (theme) => theme.palette.secondary.grey }}>
              Cover all areas of your Image
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default UploadImagesBox