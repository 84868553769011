import { Box } from "@material-ui/core";
import React from "react";
import PlanCards from "../planCard";

const Plan = () => {
  return (
    <>
      {" "}
      <Box pt={5} pb={3}>
        <PlanCards />
      </Box>
    </>
  );
};

export default Plan;
