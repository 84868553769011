import { combineReducers } from "@reduxjs/toolkit";
import loginSlicer from "../features/signIn/signIn.slicer";
import ToggleReducer from "./buttonSlice";
import imageUploadSlicer from "../features/imageUpload/imageUpload.slicer";
import planFormSlicer from "../features/planForm/planForm.slicer";

const rootReducer = combineReducers({
  LoginSlicer: loginSlicer,
  movies: ToggleReducer,
  imageUpload: imageUploadSlicer,
  formUpload: planFormSlicer,
});

export default rootReducer;
